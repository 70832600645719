.layout-1{
    $shape-size: var(--vertical-spacing);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    overflow: hidden;
    >.title-wrapper{
        >h3{
            @include text5();
            width: 56%;
            margin-bottom: calc(var(--baseline) + var(--subdiv) * 4);
            // 
            padding-left: var(--side-spacing);
        }
    }
    >.content-wrapper{
        display: flex;
        overflow: hidden;
        >.list-wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.sub-title{
                position: relative;
                display: flex;
                align-items: center;
                margin-bottom: calc(var(--baseline) + var(--subdiv) * 2);
                user-select: none;
                >h4{
                    @include subtitle-1();
                    padding: 0;
                }
                &:before{
                    content: '';
                    display: block;
                    @include position(absolute,unset,unset,unset,calc(var(--subdiv) * -3));
                    width: calc(var(--subdiv) * 4);
                    height: 1px;
                    background: $color-d0;
                    transform: translateX(-100%);
                }
            }
            >ul{
                display: flex;
                flex-wrap: wrap;
                overflow-x: hidden;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
            &:first-child{
                >.sub-title{
                    padding-left: var(--side-spacing);
                    // 
                }
                >ul{
                    position: relative;
                    width: calc(100% - var(--bonus-spacing) + var(--list-spacing) - var(--side-spacing));
                    margin-left: calc(-1 * var(--list-spacing));
                    padding-right: var(--bonus-spacing);
                    // 
                    padding-left: var(--side-spacing);
                    margin-bottom: var(--vertical-spacing);
                    // padding-bottom: var(--side-margins);
                    >span{
                        display: block;
                        display: block;
                        pointer-events: none;
                        &.top-shape, &.bottom-shape{
                            // .bottom-shape used for fix in firefox, look at bottom of this
                            // TODO remove this objects if not needed
                            opacity: 0;
                            //
                            @include transition(background);
                            // display: none;
                            position: sticky;
                            height: $shape-size;
                            width: 100%;
                            background-repeat: no-repeat;
                        }
                        &.top-shape{
                            top: 0;
                            margin-top: calc(#{$shape-size} * -1);
                            background-image: linear-gradient(180deg, rgba($color-w0, 1), rgba($color-w0, 0));
                            background-position-y: calc(#{$shape-size} * -1);
                        }
                        &.bottom-shape{
                            bottom: 0;
                            margin-top: calc(var(--left-list-items-spacing) * -1);
                            background-image: linear-gradient(0deg, rgba($color-w0, 1), rgba($color-w0, 0));
                            background-position-y: $shape-size;
                        }
                    }
                    >li{
                        width: calc(100% / var(--columns-1) - var(--left-list-items-spacing)); 
                        margin-bottom: var(--left-list-items-spacing);
                        margin-left: var(--left-list-items-spacing);
                    }
                    &.top-shape-view{
                        >span{
                            &.top-shape{
                                background-position-y: 0px;
                            }
                        }
                    }
                    &.bottom-shape-view{
                        >span{
                            &.bottom-shape{
                                background-position-y: 0px;
                            }
                        }
                    }
                }
            }
            &:last-child{
                padding-left: var(--bonus-spacing);
                // 
                padding-right: var(--side-spacing);
                >ul{
                    width: 100%;
                }
            }
        }
    }
    &.col-3-2{
        >.content-wrapper{
            >.list-wrapper{
                --right-list-items-spacing: calc(var(--subdiv) * 3);
                --left-list-items-spacing: var(--baseline);
                --bonus-spacing: calc(var(--baseline) + var(--subdiv) * 4);
                --list-spacing: var(--left-list-items-spacing);
                --columns-1: 3;
                --columns-2: 2;
                &:last-child{
                    max-width: calc(100% / 3 - var(--bonus-spacing));
                    >ul{
                        margin-bottom: calc(var(--right-list-items-spacing) * -1);
                        >*{
                            width: calc(100% / var(--columns-2) - var(--right-list-items-spacing) / 2);
                            margin-bottom: var(--right-list-items-spacing);
                            &:not(:nth-child(2n)){
                                margin-right: var(--right-list-items-spacing);
                            }
                        }
                    }
                }
            }
        }
    }
    &.col-2-1{
        >.content-wrapper{
            >.list-wrapper{
                --right-list-items-spacing: calc(var(--subdiv) * 3);
                --left-list-items-spacing: var(--baseline);
                --bonus-spacing: calc(var(--baseline) * 2 + var(--subdiv) * 3);
                --list-spacing: var(--left-list-items-spacing);
                --columns-1: 2;
                &:last-child{
                    max-width: calc(100% * .35 - var(--bonus-spacing));
                    >ul{
                        >*{
                            // width: calc(100% + var(--list-spacing));
                            width: calc(100% - var(--pr-checkbox-2-left-spacing));
                            &:not(:last-child){
                                margin-bottom: var(--right-list-items-spacing);
                            }
                        }
                    }
                }
            }
        }
    }
    &.transition-hold{
        *{
            transition: none;
        }
    }
    @media only screen and (max-width: $brake2-2) {
        &.col-3-2{
            >.content-wrapper{
                >.title-wrapper{
                    >h3{
                        width: 75%;
                    }
                }
                >.list-wrapper{
                    --columns-1: 3;
                    --columns-2: 1;
                    &:last-child{
                        max-width: calc(100% / 4 - var(--bonus-spacing));
                        >ul{
                            >*{
                                &:not(:nth-child(2n)){
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.col-2-1{
            >.content-wrapper{
                >.list-wrapper{
                    &:last-child{
                        max-width: calc(100% / 4 - var(--bonus-spacing));
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $brake2-4) {
        &.col-2-1{
            >.content-wrapper{
                >.list-wrapper{
                    --columns-1: 1;
                }
            }
        }
        &.col-3-2{
            >.content-wrapper{
                >.list-wrapper{
                    --columns-1: 2;
                    --columns-2: 1;
                    &:last-child{
                        >ul{
                            >*{
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: $brake2-5) {
        >.title-wrapper{
            >h3{
                width: calc(100% - var(--side-spacing) * 2);
            }
        }
        &.col-3-2{
            >.content-wrapper{
                >.list-wrapper{
                    --columns-1: 1;
                    --columns-2: 1;
                }
            }
        }
    }
    // @media screen and (max-width: $brake4) {
    @media only screen and (max-width: $brake4), only screen and (max-height: $brake-vertical) {
        >.title-wrapper{
            >h3{
                margin-bottom: calc(var(--baseline) + var(--subdiv));
            }
        }
        &.col-3-2, &.col-2-1{
            >.content-wrapper{
                >.list-wrapper{
                    --left-list-items-spacing: calc(var(--subdiv) * 2);
                    --list-spacing: var(--left-list-items-spacing);
                    --bonus-spacing: 0;
                    --columns-1: 2;
                    >.sub-title{
                        display: none;
                    }
                    &:first-child{
                        >ul{
                            padding: 0 var(--side-spacing);
                            width: calc(100% + var(--list-spacing) - var(--side-spacing) * 2);
                            >span{
                                &.top-shape{
                                    display: none;
                                }
                            }
                            &.bottom-shape-view{
                                >.bottom-shape{
                                    background-position-y: $shape-size;
                                }
                            }
                        }
                    }
                    &:last-child{
                        display: none;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $brake5) {
        &.col-3-2, &.col-2-1{
            >.content-wrapper{
                >.list-wrapper{
                    &:first-child{
                        >ul{
                            width: calc(100% - var(--side-spacing) * 2);
                            margin-left: unset;
                            >li{
                                width: 100%;
                                margin-left: unset;
                                margin-bottom: calc(var(--subdiv) * 2);
                            }
                        }
                    }
                }
            }
        }
    }
}
// fix for firefox
.questions-onboarding-wrapper{
    &.firefox{
        .bottom-shape{
            position: relative !important;
        }
    }
}
