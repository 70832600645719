@import '../../styles/--scss-vars.scss';

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  @include position(absolute, 0, 0, 0, 0);
  // min-height: $column4;
  // overflow-y: auto;
  // overflow-x: none;
  &.profile {
    z-index: 1301;
  }
  &::before {
    content: '';
    // background-color: $color-d0-a;
    @include position(absolute, 0, 0, 0, 0);
    margin: auto;
  }
  @media (max-width: $brake4-2) {
    position: fixed;
    z-index: 1001;
  }
  &.show {
    display: flex;
  }
  .backoverlay {
    background-color: $color-d0-a;
    @include position(absolute, 0, 0, 0, 0);
    margin: auto;
    z-index: 1;
    backdrop-filter: blur(3px);
  }
  .popup-dailog {
    z-index: 1000;
    max-width: $column6;
    width: calc(100% - $gutter);
    margin: auto;
    background-color: $color-d14;
    border-radius: 20px;
    //border: 1px solid $color-w0;
    padding: calc(var(--subdiv) * 8) calc(var(--subdiv) * 3)
      calc(var(--subdiv) * 8);
    @include border-radius(calc(var(--subdiv) * 4));
    text-align: center;
    position: relative;
    top: $subdiv * 2;
    .inner-wrap {
      max-width: $column6 - $spacer2;
      width: 100%;
      margin: auto;
      overflow-y: auto;
      overflow-x: hidden;

      .sec-head {
        margin-bottom: calc(var(--subdiv) * 4);
        .title {
          text-transform: uppercase;
          margin-bottom: 0;
          font-size: calc(var(--subdiv) * 6);
          font-family: 'Helvetica Neue', sans-serif;
        }
        p {
          font-size: $subdiv * 2 + 2;
          a,
          span {
            color: $color-w0;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .notification {
        font-size: $subdiv * 2 + 2;
        line-height: 1.3;
        margin-bottom: calc(var(--subdiv) * 3);
        &.success {
          color: $color-x0;
        }
        &.error {
          color: $color-e0;
        }
      }
      .upload-photo {
        position: relative;
        height: 120px;
        width: 100%;
        input[type='file'] {
          opacity: 0;
          -webkit-appearance: none;
          // width: $spacer4;
          // height: $spacer4;
        }
        label {
          @include border-radius(50%);
          background-color: $color-d6;
          @include position(absolute, 0, 0, 0, 0);
          margin: auto;
          max-width: $spacer4;
          width: 100%;
          height: 100%;
          cursor: pointer;
          .outer {
            text-align: center;
            .icon {
              // margin-bottom: calc(var(--subdiv));
              position: relative;
              &.edit-profile {
                img {
                  @include border-radius(50%);
                }
                .image-overlay {
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  padding: $subdiv * 2;
                  background: $color-d0-a;
                  span {
                    position: absolute;
                    width: $subdiv * 6;
                    height: $subdiv * 6;
                    border: 1px solid $color-w9-a;
                    @include border-radius(50%);
                    padding: $subdiv;
                    display: flex;
                    right: $subdiv;
                    bottom: $subdiv;
                    background-color: $color-d0;
                    cursor: pointer;
                    img {
                      opacity: 0.5;
                    }
                  }
                }
              }
            }
            .text {
              color: $color-d7;
              font-size: $subdiv * 2;
              line-height: 1.2;
            }
          }
          .preview {
            @include position(absolute, 0, 0, 0, 0);
            margin: auto;
            max-width: $spacer4;
            width: 100%;
            height: 100%;
            @include border-radius(50%);
            overflow: hidden;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .info {
        font-size: $subdiv * 2 - 1;
        text-align: left;
        line-height: 1.2;
        margin-bottom: 0;
      }
      .btn-wrap {
        margin: calc(var(--subdiv) * 4) 0 calc(var(--subdiv) * 2 + 2px);
        button {
          text-transform: uppercase;
          &:last-child {
            margin-left: $subdiv + 3;
          }
        }
      }
      .forgot {
        line-height: 1.2;
        text-align: left;
        a,
        .make-link {
          color: $color-w0;
          font-size: $subdiv * 2 + 2;
          font-weight: 700;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &[class*='noscroll'] {
        overflow-x: visible;
        overflow-y: visible;
      }
      .skip {
        text-transform: uppercase;
        padding: 16px;
        border: 1px solid $color-f0;
        a,
        .make-link {
          color: $color-f0;
          font-size: $subdiv * 2 + 3;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    &.style2 {
      padding: $subdiv * 4 $subdiv * 6;
      .sec-head {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .title {
          color: $color-f0;
          font-size: $subdiv * 4 - 2;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1.2;
        }
        span {
          display: inline-block;
          color: $color-f0;
          font-size: $subdiv * 2 + 2;
          font-weight: 300;
          margin-left: $subdiv;
        }
      }
      .buy-livecoin {
        .style2 {
          max-width: 100%;
          .tile-style2 {
            background: none;
            border: 1px solid $color-f0;
            .coin-style2 {
              color: $color-f0;
            }
            .price-style2 {
              color: $color-f0;
            }
          }
        }
        .my-3 {
          margin: $subdiv * 4 0;
        }
      }
      .btn-wrap {
        &.text-left {
          text-align: left;
        }
      }
      .wallet-balance {
        color: $color-f0;
        font-size: $subdiv * 2 + 2;
        font-weight: 300;
        text-align: left;
        margin-top: $subdiv * 2;
      }
    }
  }
  &.stage {
    .popup-dailog {
      padding: calc(var(--subdiv) * 8) calc(var(--subdiv) * 3);
      z-index: 1;
      max-width: $column7 - $spacer2;
      background-color: $color-d0-a;
      height: auto;
      text-align: left;
      .inner-wrap {
        max-width: $column6 - $spacer2;
        margin: auto;
        .image-box {
          width: $spacer2;
          margin-right: $subdiv * 4;
          img {
            height: $spacer2;
            object-fit: contain;
          }
        }
        .content-box {
          width: calc(100% - calc($spacer2 + $subdiv * 4));
          p {
            max-width: $column4;
            font-size: $subdiv * 5 - 1;
            @media (max-width: $brake4) {
              font-size: $subdiv * 3 + 1;
            }
            @media (max-width: $brake4-2) {
              font-size: $subdiv * 3 - 1;
            }
          }
          .note {
            margin-top: $subdiv * 5;
            font-size: $subdiv * 5 - 1;
            font-weight: 700;
            @media (max-width: $brake4) {
              font-size: $subdiv * 3 + 1;
            }
            @media (max-width: $brake4-2) {
              font-size: $subdiv * 3 - 1;
              margin-top: 0;
            }
            button {
              &:not(:last-of-type) {
                margin-right: $subdiv * 3;
              }
            }
          }
        }
      }
      .close {
        @include position(absolute, $subdiv * 3, $subdiv * 3, auto, auto);
        @media (max-width: $brake4-2) {
          @include position(
            absolute,
            $subdiv * 2 - 2,
            $subdiv * 2 - 2,
            auto,
            auto
          );
        }
      }
    }
    &::before {
      display: none;
    }
  }
  &.stage-invite {
    .popup-dailog {
      padding: calc(var(--subdiv) * 8) calc(var(--subdiv) * 3);
      z-index: 1;
      max-width: $column8 - $spacer2;
      background-color: transparent;
      height: auto;
      border: 0;
      text-align: left;
      h3 {
        font-size: $subdiv * 6;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
      }
      .inner-wrap {
        max-width: $column7 - $spacer4;
        margin: auto;
        .image-box {
          width: $spacer2;
          margin-right: $subdiv * 4;
          img {
            height: $spacer2;
            object-fit: contain;
          }
        }
        .content-box {
          width: calc(100% - calc($spacer2 + $subdiv * 4));
          h3 {
            font-size: $subdiv * 6;
            font-weight: 900;
            text-transform: uppercase;
            text-align: left;
          }
          p.desc {
            font-size: $subdiv * 3;
          }
          .btn-wrap {
            margin-top: $subdiv * 5;
            button {
              &:not(:last-of-type) {
                margin-right: $subdiv * 3;
              }
            }
          }
        }
      }
    }
    &::before {
      display: none;
    }
  }
  .close {
    @include border-radius(50%);
    width: $subdiv * 5 + 1;
    height: $subdiv * 5 + 1;
    background-color: $color-d0;
    border: 1px solid $color-w0;
    position: relative;
    cursor: pointer;
    @media (max-width: $brake4-2) {
      width: $subdiv * 4;
      height: $subdiv * 4;
    }
    &::before {
      content: '';
      @include position(absolute, 0, 0, 0, 0);
      margin: auto;
      width: $subdiv - 3;
      height: $subdiv * 2 + 2;
      background-color: $color-w0;
      transform: rotate(45deg);
    }
    &::after {
      content: '';
      @include position(absolute, 0, 0, 0, 0);
      margin: auto;
      width: $subdiv - 3;
      height: $subdiv * 2 + 2;
      background-color: $color-w0;
      transform: rotate(-45deg);
    }
  }
  .ursa-logo {
    margin-bottom: $subdiv * 2;
  }
  .buy-livecoin {
    p {
      max-width: $column2;
      width: 100%;
      text-align: center;
      line-height: 1.2;
      font-weight: 700;
      margin: auto;
    }
    .my-3 {
      margin: $subdiv * 5 auto;
    }
  }
}

.Mui-selected {
  color: #ffffff;
}
