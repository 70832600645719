@mixin two-app-in-row-at-panel() {
  $halfs-spacing: calc(var(--subdiv) * 2);
  $first-half: calc((100% - #{$halfs-spacing}) / 2);
  &:first-child {
    width: $first-half;
    margin-right: $halfs-spacing;
  }
  &:last-child {
    width: calc(100% - #{$first-half} - #{$halfs-spacing});
  }
}

@mixin items-align-absolute($wrapper-widht, $padding-top) {
  width: $wrapper-widht;
  margin: 0;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
    padding-top: $padding-top;
  }
  > img,
  > div,
  > span,
  > p,
  > li {
    position: absolute;
    margin: auto;
    left: 0%;
    top: 0%;
    height: auto;
    width: 100%;
    max-width: initial;
  }
}

@mixin border-bottom-hover-animation {
  &:after {
    @include transition(width);
    content: '';
    display: block;
    border-bottom: 1px solid $color-d0;
    width: 0%;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin ios-touch-scroll() {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin transition(
  $property: all,
  $duration: $base-time,
  $delay: 0s,
  $timing-function: $ease-out-apple1
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
  transition-delay: $delay;
}


//
//    dont used
//
// @mixin multi-line-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
//   overflow: hidden;
//   position: relative;
//   line-height: $lineHeight;
//   max-height: $lineHeight * $lineCount;
//   margin-right: -1em;
//   padding-right: 1em;
//   &:before {
//     content: '...';
//     position: absolute;
//     right: var(--side-margins);
//     bottom: 0;
//   }
// }

//Position
@mixin position(
  $position: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}


@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin rows-ellipsis($row) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $row;
  -webkit-box-orient: vertical;
}

@mixin border-opacity($size, $color, $opacity) {
  border: $size solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

@mixin text-opacity($color, $opacity) {
  color: $color; /* The Fallback */
  color: rgba($color, $opacity);
}

@mixin background-opacity($color, $opacity) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin noselection() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                               supported by Chrome and Opera */
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transform($transfofm) {
  -ms-transform: $transfofm;
  -webkit-transform: $transfofm;
  transform: $transfofm;
}


// @mixin font-smooth {
//         font-smoothing: antialiased;
//         -moz-font-smoothing: antialiased;
//         -webkit-font-smoothing: antialiased;
// }

@mixin box-sizing($parameter) {
  -webkit-box-sizing: $parameter;
  -moz-box-sizing: $parameter;
  box-sizing: $parameter;
}

@mixin placeholder($color) {
  ::-webkit-input-placeholder {
    color: ($color);
  }
  ::-moz-placeholder {
    color: ($color);
  }
  :-ms-input-placeholder {
    color: ($color);
  }
  :-moz-placeholder {
    color: ($color);
  }
}



@mixin pop-up() {
  height: 100%;
  width: 100%;
  @include position(fixed, 0, 0, 0, 0);
  background: $color-d3-a;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  @include transition(opacity, $time4);
  z-index: 1000;
  pointer-events: none;
  visibility: hidden;

  &.in-view,
  &[class*='in-view'] {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
  > .pr-popup-inner {
    width: calc(100% - var(--side-margins) * 2);
    max-width: calc(var(--baseline) * 24 + var(--subdiv) * 1);
    margin: 0 var(--side-margins);
    background-color: $color-w0;
    border-radius: var(--radius7);
    overflow: hidden;
    position: relative;

    > .content {
      width: calc(100% - var(--baseline) * 3 - var(--subdiv) * 4);
      padding: 0 calc(var(--baseline) + var(--subdiv) * 5);

      &.header {
        padding-top: calc(var(--baseline) + var(--subdiv) * 5);
        border-top-left-radius: var(--radius7);
        border-top-right-radius: var(--radius7);
      }
      &.footer {
        padding-bottom: calc(var(--baseline) + var(--subdiv) * 5);
        border-bottom-left-radius: var(--radius7);
        border-bottom-right-radius: var(--radius7);
      }
    }
    > .pr-x-mark-wrapper {
      height: calc(var(--subdiv) * 5);
      width: calc(var(--subdiv) * 5);
      margin: 0;
      @include position(
        absolute,
        calc(var(--baseline) + var(--subdiv) * 2),
        calc(var(--baseline) + var(--subdiv) * 2),
        unset,
        unset
      );
      cursor: pointer;
      z-index: 20;

      > svg {
        pointer-events: none;

        > path {
          stroke: $color-d0;
        }
      }
    }
  }

  @media only screen and (max-width: $brake4-1) {
    > .pr-popup-inner {
      > .content {
        width: calc(100% - var(--baseline) * 2 - var(--subdiv) * 2);
        padding: 0 calc(var(--baseline) + var(--subdiv));

        &.header {
          padding-top: calc(var(--baseline) + var(--subdiv));
        }
        &.footer {
          padding-bottom: calc(var(--baseline) + var(--subdiv));
        }
      }
      > .pr-x-mark-wrapper {
        height: calc(var(--subdiv) * 5);
        width: calc(var(--subdiv) * 5);
        margin: 0;
        @include position(
          absolute,
          var(--baseline),
          var(--baseline),
          unset,
          unset
        );

        &:before {
          content: '';
          height: 200%;
          width: 200%;
          border-radius: 100%;
          @include position(absolute, -50%, unset, unset, -50%);
        }
      }
    }
  }
}
