html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/******************************
  * Global colors and shadows for add-to-calendar-button
  */

:root {
  --base-font-size-l: 16px;
  --base-font-size-m: 16px;
  --base-font-size-s: 16px;
  --font: arial, helvetica, sans-serif;
  --atcb-keyboard-focus: #1e90ff;
  --atcb-background: #f5f5f5;
  --atcb-background-hover: #fff;
  --atcb-border: #d2d2d2;
  --atcb-text: #333;
  --atcb-text-2: #000;
  --atcb-close-background: #e5e5e5;
  --atcb-close-text: #777;
  --atcb-overlay-background: rgba(20 20 20 / 25%);
  --atcb-modal-background: #f5f5f5;
  --atcb-modal-buttons-bar: #c6c8cd;
  --atcb-modal-btn-background: #f5f5f5;
  --atcb-modal-btn-sec-background: #e2e1e6;
  --atcb-modal-btn-background-hover: #fff;
  --atcb-modal-btn-text: #2e2e2e;
  --atcb-modal-btn-text-hover: #161616;
  --atcb-modal-btn-sec-text: #666567;
  --atcb-modal-date-btn-text: #1d1d1e;
  --atcb-modal-date-btn-text-2: #3a3a3f;
  --atcb-modal-date-btn-cal-text: #fff;
  --atcb-modal-date-btn-cal-text-2: #d3d2d7;
  --atcb-modal-date-btn-cal-background: #313132;
  --atcb-modal-date-btn-background: #eae9ed;
  --atcb-modal-date-btn-background-hover: #fff;
  --atcb-shadow-button: rgba(0 0 0 / 30%) 2px 5px 18px -1px, rgba(0 0 0 / 25%) 2px 2px 10px -3px;
  --atcb-shadow-button-hover: rgba(0 0 0 / 40%) 2px 5px 18px -1px, rgba(0 0 0 / 35%) 2px 2px 10px -3px;
  --atcb-shadow-button-active: rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 45%) 2px 2px 10px -3px;
  --atcb-shadow-list: rgba(0 0 0 / 20%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 2px 2px 10px -3px;
  --atcb-shadow-list-modal: rgba(0 0 0 / 60%) 3px 6px 40px -5px, rgba(0 0 0 / 60%) 3px 3px 15px -4px;
  --atcb-shadow-modal: drop-shadow(5px 8px 30px rgba(0 0 0 / 70%));
  --atcb-shadow-modal-date-btn: rgba(0 0 0 / 40%) 1px 3px 15px -4px, rgba(0 0 0 / 20%) 1px 1px 8px -4px;
  --atcb-shadow-modal-date-btn-hover: rgba(0 0 0 / 40%) 4px 6px 18px -1px, rgba(0 0 0 / 35%) 4px 5px 25px -2px;
  --atcb-shadow-modal-button: rgba(0 0 0 / 10%) 2px 3px 10px -3px,rgba(0 0 0 / 25%) 1px 1px 8px -4px;
  --atcb-shadow-modal-button-hover: rgba(0 0 0 / 35%) 3px 5px 15px -2px,rgba(0 0 0 / 20%) 2px 4px 25px -6px;
  --atcb-checkmark-background: radial-gradient(circle,#fff 0,rgba(255 255 255 / 80%) 40%,rgba(255 255 255 / 0%) 70%);
  --bg-cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23777' width='16' height='16' viewBox='0 0 122.878 122.88'%3E%3Cpath d='M1.426 8.313a4.87 4.87 0 0 1 0-6.886 4.87 4.87 0 0 1 6.886 0l53.127 53.127 53.127-53.127a4.87 4.87 0 0 1 6.887 0 4.87 4.87 0 0 1 0 6.886L68.324 61.439l53.128 53.128a4.87 4.87 0 0 1-6.887 6.886L61.438 68.326 8.312 121.453a4.87 4.87 0 0 1-6.886 0 4.87 4.87 0 0 1 0-6.886l53.127-53.128L1.426 8.313h0z'/%3E%3C/svg%3E") 16 16, crosshair;
  --icon-ms365-color: #ea3e23;
  --icon-yahoo-color: #5f01d1;
  --icon-filter: none;
}