.slidesPage {
  display: flex;
  height: 100%;
  width: 100%;

  > div {
    width: 100%;
  }
  @media screen and (max-width: $brake4-1) {
    flex-direction: column;
  }

  > .mobile-menu {
    height: 100%;
    width: 100%;
    @include position(fixed, 0, unset, unset, 0);
    display: flex;
    justify-content: center;
    background: $color-d3-a;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    @include transition(opacity, $time4);

    > .list {
      height: fit-content;
      width: calc(
        100% - var(--side-margins) * 2 - var(--baseline) * 2 - var(--subdiv) * 4
      );
      padding: calc(var(--baseline) + var(--subdiv) * 2)
        calc(var(--baseline) + var(--subdiv) * 2);
      background-color: $color-w0;
      border-radius: var(--radius7);
      margin-top: var(--header-height);
      max-height: calc(100vh - var(--header-height) * 3);
      overflow-y: auto;

      > li {
        @include body-text();
        color: $color-d0;
        user-select: none;
        @include transition(color, $time3);

        &:not(:first-child) {
          margin-top: calc(var(--subdiv) * 6);
        }
        &:last-child {
          margin-bottom: calc(var(--subdiv) * 2);
        }
        &.active {
          color: $color-f0;
        }
      }
    }
    &.opened {
      opacity: 1;
      pointer-events: unset;
    }
  }

  .nav {
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: var(--baseline) var(--side-margins);
    box-sizing: border-box;
    @include background-opacity($color-w0, 0.7);
    backdrop-filter: blur(20px);
    &:not(.chrome):not(.safari):not(.opera):not(.crios) {
      background-color: $color-w1-a;
    }
    .btn-back,
    .btn-next {
      margin: 0 calc(var(--subdiv) * 2);
      width: fit-content;
      .pr-button-wrapper {
        padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 10);
        width: fit-content;
      }
    }
  }

  .first-side {
    width: calc(100% - var(--side-margins) * 1);
    max-width: var(--column3);
    padding: 0 0 0 var(--side-margins);
    padding-top: var(--header-height);
    z-index: 1;
    display: flex;
    position: relative;
    &:before {
      display: none;
      content: '';
      @include position(absolute, 0, 0, unset, calc(-1 * var(--side-margins)));
      height: 100%;
      width: calc(100% + var(--side-margins) * 2);
      background-color: rgba($color-w0, 0.8);
      backdrop-filter: blur($blur6);
    }
    > .menu-CTA {
      position: relative;
      height: fit-content;
      padding: var(--subdiv);
      margin-right: calc(var(--subdiv) * 7);
      cursor: pointer;
      z-index: 100;
      > span {
        display: block;
        height: 2px;
        background: $color-d0;
        &:nth-child(1) {
          width: calc(var(--subdiv) * 5);
        }
        &:nth-child(2) {
          width: calc(var(--subdiv) * 2);
        }
        &:nth-child(3) {
          width: calc(var(--subdiv) * 3);
        }
        &:not(:last-child) {
          margin-bottom: var(--subdiv);
        }
      }
    }
    > ul {
      position: sticky;
      top: calc(var(--subdiv) * 2);
      margin-top: calc(var(--subdiv) * 2);
      overflow: scroll;
      &:not(.chrome):not(.safari):not(.opera):not(.crios) {
        > li {
          &:before {
            transform: translateY(-50%);
            @include transition(transform, $base-time);
          }
          &:after {
            @include transition(transform, $base-time);
          }
        }
      }
      > li {
        @include body-text();
        width: fit-content;
        color: $color-d0;
        overflow: visible !important;
        cursor: pointer;
        user-select: none;
        position: relative;
        padding-left: var(--baseline);
        &:before {
          $size: 6px;
          content: '';
          background: $color-d0;
          font-weight: bold;
          border-radius: $size * 2;
          display: flex;
          align-items: center;
          justify-content: center;
          width: $size;
          height: $size;
          @include transition(transform, $base-time * 3);
          position: absolute;
          left: var(--subdiv);
          top: 50%;
          transform: translateY(-50%);
          line-height: 0;
        }
        &:after {
          $size: 16px;
          content: '';
          width: $size;
          height: $size;
          border-radius: $size * 2;
          background: $color-f0;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) scale(0);
          left: 0;
          z-index: -1;
          opacity: 0;
          @include transition(transform, $base-time * 3);
          line-height: 0;
        }
        &.prev {
          color: $color-d3;
          pointer-events: none;
          user-select: none;
          &:before {
            color: $color-d3;
            opacity: 0;
          }
        }
        &.active {
          color: $color-f0;
          &:after {
            transform: translateY(-50%) scale(1);
            opacity: 1;
            line-height: 0;
          }
          &:before {
            background: $color-w0;
            line-height: 0;
          }
        }
        &:hover {
          color: $color-f0-1;
        }
      }
    }
    @media screen and (max-width: $brake2-1) {
      > ul > li:before {
        left: calc(var(--subdiv) + 1px);
      }
    }
    @media screen and (min-width: $brake4-1 + 1px) {
      > .menu-CTA {
        display: none;
      }
      > ul {
        > li {
          @include ellipsis();
          &:not(:first-child) {
            margin-top: calc(var(--subdiv) * 4);
          }
          &:last-child {
            margin-bottom: calc(
              var(--popup-footer-spacer) + var(--side-margins)
            );
          }
        }
      }
    }
    @media screen and (max-width: $brake4-1) {
      align-items: center;
      max-width: unset;
      padding-bottom: calc(var(--subdiv) * 4);
      &:before {
        display: block;
      }
      > ul {
        position: relative;
        top: unset;
        display: flex;
        max-width: 100%;
        width: 100%;
        width: calc(100% + var(--side-margins));
        margin-top: unset;
        overflow: scroll;
        > li {
          color: $color-d0;
          white-space: nowrap;
          &.prev {
            color: $color-d3;
          }
          &::before {
            display: none;
          }
          &:first-child {
            padding-left: 0;
          }
          &:not(:first-child) {
            margin-left: var(--subdiv);
          }
          &:last-child {
            padding-right: var(--side-margins);
          }
        }
      }
    }
  }
  @media screen and (max-width: $brake4-2) {
    .nav {
      justify-content: space-between;
      .btn-back,
      .btn-next {
        width: 100%;
        .pr-button-wrapper {
          width: 100%;
          box-sizing: border-box;
          padding: calc(var(--subdiv) * 2) calc(var(--subdiv) * 10);
        }
      }
    }
  }
}
