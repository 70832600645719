@import 'typography-rythm.scss', 'colors.scss', 'breackpoints.scss',
  'typography-scale.scss',  url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600;700;800;900&display=swap');

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica-LightOblique.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica-Oblique.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../../public/assets/fonts/Helvetica-BoldOblique.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

$font-family1: 'Helvetica';
$font-family2: 'Work Sans', sans-serif;

@mixin bold {
  -webkit-font-smoothing: subpixel-antialiased;
}
@mixin text-1 {
  text-1: text-1;
  @include font($font-family1, 400, var(--font-size1), 0em, 1.5, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.384em;
  }
}
@mixin subtitle-0 {
  subtitle-0: subtitle-0;
  @include font($font-family1, 700, var(--font-size1), 0.1em, 1.25, uppercase);
}
@mixin subtitle-1 {
  subtitle-1: subtitle-1;
  @include font($font-family1, 400, var(--font-size0), 0.1em, 1.25, uppercase);
}
@mixin text0 {
  text0: text0;
  @include font($font-family1, 400, var(--font-size2), -0.01em, 1.5, none);
}
@mixin text1 {
  text1: text1;
  @include font($font-family1, 500, var(--font-size4), -0.03em, 1.428, none);
}
@mixin text2 {
  text2: text2;
  @include font($font-family1, 400, var(--font-size5), -0.03em, 1.4, none);
}
@mixin text3 {
  text3: text3;
  @include font($font-family1, 500, var(--font-size6), -0.03em, 1.33, none);
}
@mixin text5 {
  text5: text5;
  @include font($font-family2, 900, var(--font-size9), 0.1em, 1.14, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.307em;
  }
  @media screen and (max-width: $brake2-2) {
    @include font($font-family2, 900, var(--font-size8), 0.1em, 1.307, none);
  }
  @media only screen and (max-width: $brake4),
    only screen and (max-height: $brake-vertical) {
    @include font($font-family2, 900, var(--font-size6), 0.1em, 1.307, none);
  }
}
@mixin text7 {
  text7: text7;
  @include font($font-family1, 400, var(--font-size12), -0.01em, 1.13, none);
}
@mixin body-text {
  body-text: body-text;
  @include font($font-family1, 400, var(--font-size3), -0.03em, 1.5, none);
  @media screen and (max-width: $brake2-1) {
    line-height: 1.5em;
  }
}
