// OPACITY
$opacity0: 0.95;
$opacity1: 0.82609;
$opacity2: 0.71834;
$opacity3: 0.62464;
$opacity4: 0.54317;
$opacity5: 0.47232;
$opacity6: 0.41071;
$opacity7: 0.35714;
$opacity8: 0.31056;
$opacity9: 0.13502;
// BLUR
$blur1: 5px;
$blur2: 10px;
$blur3: 15px;
$blur4: 20px;
$blur5: 25px;
$blur6: 30px;
// WHITE
$color-w0: #fff;
$color-w1-a: rgba(255, 255, 255, 0.97);
$color-w2-a: rgba(255, 255, 255, 0.94);
$color-w3-a: rgba(255, 255, 255, 0.91);
$color-w4-a: rgba(255, 255, 255, 0.87);
$color-w5-a: rgba(255, 255, 255, 0.8);
$color-w6-a: rgba(255, 255, 255, 0.78);
$color-w7-a: rgba(255, 255, 255, 0.71);
$color-w8-a: rgba(255, 255, 255, 0.62);
$color-w9-a: rgba(255, 255, 255, 0.5);
$color-w10-a: rgba(255, 255, 255, 0.27);
$color-w11-a: rgba(255, 255, 255, 0.09);
// DARK
$color-d0: rgba(0, 0, 0, 1); // #000
$color-d0-a: rgba(0, 0, 0, 0.85); // #000 width 80% opacity
$color-d1: rgba(48, 48, 48, 1); // #303030
$color-d1-a: rgba(48, 48, 48, 0.77);
$color-d2: rgb(211, 211, 211, 1); // #d3d3d3
$color-d2-a: rgba(211, 211, 211, 0.47);
$color-d3: rgba(189, 189, 189, 1); // #BDBDBD
$color-d3-a: rgba(189, 189, 189, 0.2);
// $color-d3-b: rgba(1, 14, 40, 0.05);
// $color-d3-1: rgba(204, 207, 212, 0.5);
$color-d4: rgba(39, 39, 39, 1); // #272727
$color-d4-a: rgba(1, 14, 40, 0.09);
$color-d5: rgba(196, 196, 196, 1); // #C4C4C4
$color-d5-a: rgba(1, 14, 40, 0.04);
$color-d6: rgba(72, 72, 72, 1); // #484848
$color-d6-a: rgba(1, 14, 40, 0.02);
$color-d6-b: rgba(1, 14, 40, 0.4);
$color-d0-1: rgba(1, 9, 25, 1);
$color-d0-2: rgba(0, 6, 17, 1);
$color-d0-3: rgba(255, 88, 101, 0.04);
$color-d7: rgba(128, 128, 128, 1); // #808080
$color-d7-a: rgba(189, 189, 189, 0.1);
$color-d8: rgba(107, 107, 107, 1);
$color-d8-a: rgba(47, 128, 237, 0.06);
$color-d9: rgba(0, 0, 0, 0.05);
$color-d10: rgba(196, 196, 196, 1);
$color-d11: rgba(148, 154, 165, 1);
$color-d12: rgba(245, 247, 252, 1.5);
$color-d13: rgba(0, 0, 0, 1);
$color-d14: rgba(24, 28, 39, 1); // #181C27
// BLUE
$color-f0: hsl(26, 90%, 81%); // FAC8A3
$color-f1: rgba(62, 89, 184, 1); // 3E59B8
$color-f1-a: rgba(62, 89, 184, 0.85);
$color-f2: rgba(0, 194, 255, 1); // 00C2FF
$color-f2-a: rgba(74, 133, 251, 0.57);
$color-f3: rgba(197, 216, 254, 1);
$color-f3-a: rgba(74, 133, 251, 0.32);
$color-f4: rgba(222, 233, 254, 1);
$color-f4-a: rgba(74, 133, 251, 0.18);
$color-f5: rgba(239, 244, 255, 1);
$color-f5-a: rgba(74, 133, 251, 0.09);
$color-f6: rgba(250, 251, 255, 1);
$color-f6-a: rgba(74, 133, 251, 0.03);
$color-f0-1: rgba(67, 121, 228, 1);
$color-f0-2: rgba(55, 98, 186, 1);
$color-f0-3: rgba(21, 60, 140, 1);
$color-f0-3-a: rgba(21, 60, 140, 0.9);
$color-f0-4: rgba(45, 79, 151, 1);
$color-f0-5: rgba(214, 226, 255, 1);
$color-f0-6: rgba(247, 250, 255, 1);
$color-f7: rgba(60, 90, 154, 1);

// RED
$color-e0: rgba(255, 0, 0, 1); // #FF0000
$color-e0-a: rgba(255, 0, 0, 0.85);
$color-e0-a1: rgba(255, 0, 0, 0.57);
$color-e0-a2: rgba(255, 0, 0, 0.32);
$color-e0-a3: rgba(255, 0, 0, 0.18);
$color-e0-a4: rgba(255, 0, 0, 0.09);
$color-e0-a5: rgba(255, 0, 0, 0.03);
// $color-e7-a: rgba(255, 88, 101, 0.8);
// $color-e8-a: rgba(255, 156, 164, 0.8);
// $color-e9: rgba(255, 88, 101, 1);
// $color-e9-a: rgba(255, 88, 101, 0.09);
// $color-e10: rgba(235, 67, 53, 1);

// x
$color-x0: rgba(86, 186, 60, 1); // #56BA3C
$color-x1: rgba(50, 214, 9, 1); // #32D609
$color-x1-a: rgba(15, 221, 122, 0.73);
$color-x2: rgba(137, 238, 190, 1);
$color-x2-a: rgba(15, 221, 122, 0.49);
$color-x3: rgba(178, 244, 212, 1);
$color-x3-a: rgba(15, 221, 122, 0.32);
$color-x4: rgba(207, 248, 228, 1);
$color-x4-a: rgba(15, 221, 122, 0.2);
$color-x5: rgba(226, 251, 239, 1);
$color-x5-a: rgba(15, 221, 122, 0.12);
$color-x6: rgba(243, 253, 248, 1);
$color-x6-a: rgba(15, 221, 122, 0.05);
$color-x0-1: rgba(9, 139, 77, 1);
$color-x0-2: rgba(6, 95, 52, 1);
$color-x7: rgb(255, 244, 79);
$color-x8: rgba(255, 186, 49, 0.7);
$color-x8-a: rgba(255, 186, 49, 1);
$color-x9: rgba(52, 168, 83, 1);

// BG color
$color-bg: #f5f7fc;
