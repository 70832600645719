@import '../--scss-vars.scss';
.dummy-card-data-list {
  width: calc(100% / 3 - var(--baseline) * (3 - 1) / 3);
  min-height: calc(var(--baseline) * 11.5);
  cursor: pointer;
  align-items: center;
  padding: calc(var(--subdiv) * 4) calc(var(--gutter) * -1);
  border-radius: var(--radius8);
  background-color: $color-w0;
  box-shadow: 0px 0px 44px rgba(23, 41, 78, 0.08);
  box-sizing: border-box;
  transition: 0.4s ease-in-out;

  [class*='make-link']:hover:after {
    width: 0;
  }
  @media only screen and (max-width: $brake4-1 - 1px) {
    width: 100%;
    margin-bottom: var(--baseline);
    margin-right: 0 !important;
  }
  @media screen and (max-width: $brake4) {
    padding: calc(var(--subdiv) * 4);
  }

  &.loading {
    h3 {
      margin-bottom: calc(var(--subdiv) * 2);
    }
    [class*='pr-skelton_circle'] {
      border-radius: 0;
    }
  }

  .dummy-card-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: calc(var(--font-size2));

    .dummy-card-icon-main {
      box-sizing: border-box;
      max-width: calc(var(--baseline) * 2);
      width: 100%;
      margin-bottom: calc(var(--subdiv) * 3);

      .dummy-card-icon {
        width: calc(var(--baseline) * 2);
        height: calc(var(--baseline) * 2);
        border-radius: 100%;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $brake4-1) {
          max-width: calc(var(--baseline) * 2);
          width: 100%;
        }
      }
    }
    .dummy-card-title-inner {
      display: inline-block;
      box-sizing: border-box;
      overflow: hidden;
      align-items: center;
      margin-right: calc(var(--subdiv) * 3);
      > div {
        margin: auto;
        margin-bottom: calc(var(--subdiv) * 2);
      }
    }
    .dummy-card-rank {
      margin-left: -10px;
      font-weight: bold;
      margin-bottom: calc(var(--subdiv) * 2);
      font: 700 var(--font-size3) / 1.307 'Lato', sans-serif, open-sans;
      position: relative;
      padding-left: 25px;

      .dummy-card-rank-star {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .dummy-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 calc(var(--subdiv) * 4);

    @media screen and (max-width: $brake5) {
      display: inline-block;
      width: 100%;
    }
    .dummy-card-text {
      @media screen and (max-width: $brake5) {
        margin: 0 0 calc(var(--subdiv) * 3);
      }
      p {
        font: 400 var(--font-size1) / 1.428 'Lato', sans-serif, open-sans;
        color: $color-d2;
        &:not(:last-child) {
          margin-bottom: calc(var(--subdiv));
        }
      }
    }
    .dummy-card-btn {
      width: calc(100% / 3 - var(--baseline) * (3 - 1) / 3);
      [class*='_h-40_'] {
        height: 40px !important;
      }
    }
  }
}
