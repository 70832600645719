/*!
 * @preserve
 *
 * ++++++++++++++++++++++
 * Add to Calendar Button
 * ++++++++++++++++++++++
 *
 * Style: Default
 *
 * Version: 1.18.8
 * Creator: Jens Kuerschner (https://jenskuerschner.de)
 * Project: https://github.com/add2cal/add-to-calendar-button
 * License: Apache-2.0 with “Commons Clause” License Condition v1.0
 * Note:    DO NOT REMOVE THE COPYRIGHT NOTICE ABOVE!
 * 
 */

/* Dark mode */

.atcb-button-wrapper.atcb-dark,
body.atcb-dark .atcb-button-wrapper.atcb-bodyScheme,
.atcb-list.atcb-dark,
body.atcb-dark .atcb-list.atcb-bodyScheme,
.atcb-modal-box.atcb-dark,
body.atcb-dark .atcb-modal-box.atcb-bodyScheme,
#atcb-bgoverlay.atcb-dark,
body.atcb-dark #atcb-bgoverlay.atcb-bodyScheme {
  --atcb-background: #2e2e2e;
  --atcb-background-hover: #373737;
  --atcb-border: #4d4d4d;
  --atcb-text: #dedede;
  --atcb-text-2: #f1f1f1;
  --atcb-close-background: #282828;
  --atcb-overlay-background: rgba(20 20 20 / 60%);
  --atcb-modal-background: #242424;
  --atcb-modal-buttons-bar: #38383a;
  --atcb-modal-btn-background: #181819;
  --atcb-modal-btn-sec-background: #2e2d30;
  --atcb-modal-btn-background-hover: #434246;
  --atcb-modal-btn-text: #dbdbdb;
  --atcb-modal-btn-text-hover: #fff;
  --atcb-modal-btn-sec-text: #b8b8b8;
  --atcb-modal-date-btn-text: #ebebf0;
  --atcb-modal-date-btn-text-2: #b5b5bd;
  --atcb-modal-date-btn-cal-text: #101010;
  --atcb-modal-date-btn-cal-text-2: #3e3e3f;
  --atcb-modal-date-btn-cal-background: #c7c7cd;
  --atcb-modal-date-btn-background: #363636;
  --atcb-modal-date-btn-background-hover: #474747;
  --atcb-shadow-button: rgba(255 255 255 / 5%) -12px -5px 20px -8px, rgba(255 255 255 / 6%) -7px -5px 15px -3px, rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 3px 3px 20px -3px;
  --atcb-shadow-button-hover: rgba(255 255 255 / 6%) -12px -5px 23px -8px, rgba(255 255 255 / 7%) -7px -5px 18px -3px, rgba(0 0 0 / 60%) 2px 5px 19px -1px, rgba(0 0 0 / 50%) 3px 3px 22px -3px;
  --atcb-shadow-button-active: rgba(255 255 255 / 7%) -12px -5px 23px -8px, rgba(255 255 255 / 8%) -7px -5px 18px -3px, rgba(0 0 0 / 70%) 2px 5px 19px -1px, rgba(0 0 0 / 60%) 3px 3px 22px -3px;
  --atcb-shadow-list: rgba(255 255 255 / 5%) -12px -5px 20px -8px, rgba(255 255 255 / 6%) -7px -5px 15px -3px, rgba(0 0 0 / 50%) 2px 5px 18px -1px, rgba(0 0 0 / 40%) 3px 3px 20px -3px;
  --atcb-shadow-list-modal: rgba(255 255 255 / 8%) -12px -5px 30px -8px, rgba(255 255 255 / 8%) -7px -5px 15px -3px, rgba(0 0 0 / 60%) 4px 6px 50px -4px, rgba(0 0 0 / 90%) 8px 12px 40px -2px;
  --atcb-shadow-modal: drop-shadow(5px 8px 30px rgba(0 0 0 / 90%));
  --atcb-shadow-modal-date-btn: rgba(255 255 255 / 10%) -8px -6px 20px, rgba(0 0 0 / 50%) 1px 3px 25px -8px,rgba(0 0 0 / 50%) 1px 1px 10px -3px;
  --atcb-shadow-modal-button: rgba(255 255 255 / 5%) -2px -2px 10px,rgba(0 0 0 / 30%) 1px 2px 8px -1px;
  --atcb-checkmark-background: radial-gradient(circle,rgba(0 0 0 / 50%) 0,rgba(0 0 0 / 30%) 40%,rgba(0 0 0 / 0%) 70%);
  --icon-ms365-color: #ea3e23;
  --icon-yahoo-color: #bebebe;
  --icon-filter: grayscale(.2);
}

.atcb {
  background: transparent;
  color: white;
  border: none;
}

/* Size Breakpoints */

/* large */
.atcb-button-wrapper,
.atcb-list,
.atcb-modal-box {
  font-size: var(--base-font-size-l);
}

/* medium */
@media (max-width: 991px) {
  .atcb-button-wrapper,
  .atcb-list,
  .atcb-modal-box {
    font-size: var(--base-font-size-m);
  }
}

/* small */
@media (max-width: 575px) {
  .atcb-button-wrapper,
  .atcb-list,
  .atcb-modal-box {
    font-size: var(--base-font-size-s);
  }
}

/******************************
 * The triggering button
 */

.atcb-button-wrapper {
  display: inline-block;
  padding: 5px;
  position: relative;
}

.atcb-button {
  align-items: center;
  background-color: var(--atcb-background);
  border: 1px solid var(--atcb-border);
  border-radius: 6px;
  box-shadow: var(--atcb-shadow-button);
  color: var(--atcb-text);
  cursor: pointer;
  display: flex;
  font-family: var(--font);
  font-size: 1em;
  font-weight: 600;
  justify-content: center;
  line-height: 1.5em;
  margin: .13em;
  max-width: 350px;
  min-width: 160px;
  padding: .65em 1em;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: auto;
  z-index: 1;
}

.atcb-button.atcb-no-text {
  min-width: 0;
}

.atcb-rtl .atcb-button {
  direction: rtl;
  text-align: right;
}

.atcb-button:focus,
.atcb-button:hover {
  background-color: var(--atcb-background-hover);
  box-shadow: var(--atcb-shadow-button-hover);
}

.atcb-button:focus-visible {
  outline: 2px solid var(--atcb-keyboard-focus);
}

.atcb-button.atcb-active:not(.atcb-modal-style, .atcb-dropoverlay),
.atcb-button.atcb-single:focus,
.atcb-button.atcb-single:hover {
  background-color: var(--atcb-background-hover);
  box-shadow: var(--atcb-shadow-button-active);
  margin: 0;
  padding: .78em 1.13em;
  z-index: 15000000;
}

.atcb-button.atcb-active.atcb-dropoverlay {
  z-index: 14000090;
}

/******************************
 * Button Icon (also base for list icons) & Text
 */

.atcb-icon {
  height: 1em;
  margin-bottom: .3em;
  margin-right: .8em;
  flex-grow: 0;
  flex-shrink: 0;
}

.atcb-rtl .atcb-icon {
  margin-right: 0;
  margin-left: .8em;
}

.atcb-no-text .atcb-icon {
  margin-right: 0;
  margin-left: 0;
}

.atcb-icon svg {
  height: 100%;
  fill: currentcolor;
  width: auto;
}

.atcb-text {
  overflow-wrap: anywhere;
}

/******************************
 * Options List
 */

.atcb-dropdown-anchor {
  bottom: 4px;
  height: 1px;
  width: 100%;
  opacity: 0;
  position: absolute;
}

.atcb-list-wrapper {
  box-sizing: border-box;
  padding: 0 4px;
  position: absolute;
  z-index: 14000090;
}

.atcb-list-wrapper.atcb-dropoverlay {
  z-index: 15000000;
}

.atcb-list {
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  box-shadow: var(--atcb-shadow-list);
  color: var(--atcb-text);
  display: block;
  font-family: var(--font);
  max-width: 100%;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  width: 100%;
}

.atcb-list-item {
  align-items: center;
  background-color: var(--atcb-background);
  border: 1px solid var(--atcb-border);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  line-height: 1.75em;
  padding: .8em;
  text-align: left;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

.atcb-rtl .atcb-list-item {
  direction: rtl;
  text-align: right;
}

.atcb-list-item:hover {
  background-color: var(--atcb-background-hover);
  color: var(--atcb-text-2);
}

.atcb-list-item:focus-visible {
  background-color: var(--atcb-background-hover);
  color: var(--atcb-keyboard-focus);
  font-size: .95em;
  font-weight: 600;
  outline: none;
}

.atcb-list-item:last-child {
  border-radius: 0 0 6px 6px;
}

.atcb-list .atcb-list-item:not(:first-child) {
  border-top-style: none;
}

.atcb-dropup .atcb-list-item:last-child {
  border-radius: 0;
  padding-bottom: 1.25em;
}

.atcb-dropup .atcb-list-item:first-child,
.atcb-dropoverlay .atcb-list .atcb-list-item:first-child,
.atcb-list.atcb-modal .atcb-list-item:first-child {
  border-radius: 6px 6px 0 0;
}

.atcb-dropoverlay .atcb-list .atcb-list-item:only-child,
.atcb-list.atcb-modal .atcb-list-item:only-child {
  border-radius: 6px;
}

.atcb-list.atcb-generated-button:not(.atcb-modal) .atcb-list-item:first-child {
  padding-top: 1.25em;
}

.atcb-dropup .atcb-list.atcb-generated-button:not(.atcb-modal) .atcb-list-item:first-child,
.atcb-dropoverlay .atcb-list.atcb-generated-button:not(.atcb-modal) .atcb-list-item:first-child {
  padding-top: .8em;
}

.atcb-dropoverlay .atcb-list,
.atcb-list.atcb-modal {
  border-radius: 6px;
}

.atcb-list.atcb-modal {
  box-shadow: var(--atcb-shadow-list-modal);
}

.atcb-list-item .atcb-icon {
  margin-bottom: .5em;
  margin-right: .6em;
  width: 1.125em;
}

.atcb-rtl .atcb-list-item .atcb-icon {
  margin-right: 0;
  margin-left: .6em;
}

.atcb-no-text .atcb-list-item .atcb-icon {
  margin: 0 auto;
}

.atcb-list-item-close {
  background-color: var(--atcb-close-background);
}

.atcb-list-item.atcb-list-item-close {
  color: var(--atcb-close-text);
}

.atcb-list-item-close svg {
  fill: currentcolor;
}

/******************************
 * Modal
 */

.atcb-modal-no-scroll {
  overflow-y: hidden;
}

.atcb-modal {
  display: block;
  margin: auto;
  min-width: 250px;
  width: auto;
  position: relative;
  z-index: 14000090;
}

.atcb-modal-box {
  filter: var(--atcb-shadow-modal);
  color: var(--atcb-text-2);
  cursor: default;
  box-sizing: border-box;  
  font-family: var(--font);
  line-height: 1.5em;
  text-align: left;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  margin-bottom: 20px;
  max-width: 32em;
  -webkit-tap-highlight-color: transparent;
}

.atcb-modal-box.atcb-rtl {
  text-align: right;
  direction: rtl;
  padding: 1.25em 1em 1.25em 2em;
}

/* icon */
.atcb-modal-icon {
  height: 2.5em;
  width: 2.5em;
  border-radius: 100%;
  background-color: var(--atcb-modal-background);
  padding: 1.75em;
  margin: auto;
}

.atcb-modal-icon svg {
  height: auto;
  fill: currentcolor;
  width: 100%;
}

/* content */
.atcb-modal-headline {
  background-color: var(--atcb-modal-background);
  border-radius: 6px 6px 0 0;
  font-size: 1.3em;
  font-weight: 600;
  line-height: 1.5em;
  padding: 1.8em 2em 1.3em;
  text-transform: uppercase;
  text-align: center;
}

.atcb-modal-icon + .atcb-modal-headline {
  margin-top: -2.6em;
  padding-top: 2.6em;
}

.atcb-modal-content {  
  background-color: var(--atcb-modal-background);
  font-size: 1em;
  padding: .3em 2.5em 2.25em;
}

@media (max-width: 575px) {
  .atcb-modal-headline {
    padding: 1.8em 1em 1em;
  }
  
  .atcb-modal-content {  
    padding: .3em 1em 2em;
  }
}

/* buttons */
.atcb-modal-buttons {
  background-color: var(--atcb-modal-buttons-bar);
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  padding: .8em 1em;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row-reverse wrap;
  align-items: center;
}

button.atcb-modal-btn,
a.atcb-modal-btn {
  background-color: var(--atcb-modal-btn-sec-background);
  border: 0;
  border-radius: 6px;
  box-shadow: var(--atcb-shadow-modal-button);
  color: var(--atcb-modal-btn-sec-text);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font);
  font-size: .9em;
  font-weight: 600;
  line-height: 1.5em;
  margin: .625em;
  padding: .625em 1.25em;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
}

button.atcb-modal-btn.atcb-modal-btn-primary,
a.atcb-modal-btn.atcb-modal-btn-primary {
  background-color: var(--atcb-modal-btn-background);
  color: var(--atcb-modal-btn-text);
}

button.atcb-modal-btn:hover,
a.atcb-modal-btn:hover {
  background-color: var(--atcb-modal-btn-background-hover);
  box-shadow: var(--atcb-shadow-modal-button-hover);
  color: var(--atcb-modal-btn-text-hover);
  text-decoration: none;
}

button.atcb-modal-btn:focus-visible,
a.atcb-modal-btn:focus-visible{
  background-color: var(--atcb-modal-btn-background-hover);
  outline: 2px solid var(--atcb-keyboard-focus);
}

body.atcb-dark a.atcb-modal-btn {
  color: var(--atcb-modal-btn-sec-text);
}

body.atcb-dark a.atcb-modal-btn:focus-visible {
  background-color: var(--atcb-modal-btn-background-hover);
  outline: 2px solid var(--atcb-keyboard-focus);
}

body.atcb-dark a.atcb-modal-btn.atcb-modal-btn-primary  {
  color: var(--atcb-modal-btn-text);
}

body.atcb-dark a.atcb-modal-btn:hover,
body.atcb-dark a.atcb-modal-btn:active {
  color: var(--atcb-modal-btn-text-hover);
}

/******************************
 * Date Buttons
 */

.atcb-subevent-btn {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-family: var(--font);
  font-size: 1em;
  box-shadow: var(--atcb-shadow-modal-date-btn);
  background-color: var(--atcb-modal-date-btn-background);
  border: 0;
  border-radius: 6px;
  padding: 0;
  margin: 0;
  touch-action: manipulation;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.atcb-subevent-btn:hover {
  align-items: center;
}

.atcb-subevent-btn:hover,
.atcb-subevent-btn:focus {
  background-color: var(--atcb-modal-date-btn-background-hover);
  box-shadow: var(--atcb-shadow-modal-date-btn-hover);  
}

.atcb-subevent-btn:focus-visible {
  outline: 2px solid var(--atcb-keyboard-focus);
}

.atcb-subevent-btn + .atcb-subevent-btn {
  margin-top: 30px;
}

.atcb-date-btn-left {
  border-radius: 4px 0 0 4px;
  align-self: stretch;
  background-color: var(--atcb-modal-date-btn-cal-background);
  color: var(--atcb-modal-date-btn-background-hover);
  padding: 15px;
  width: 2.7em;
  text-align: center;
}

.atcb-rtl .atcb-date-btn-left {
  border-radius: 0 4px 4px 0;
}

.atcb-subevent-btn:hover .atcb-date-btn-left {
  opacity: .8;
}

.atcb-date-btn-day {
  color: var(--atcb-modal-date-btn-cal-text);
  font-weight: 300;
  font-size: 2em;
}

.atcb-date-btn-month {
  color: var(--atcb-modal-date-btn-cal-text-2);
  font-weight: 600;
  font-size: 1em;
}

.atcb-date-btn-right {
  position: relative;
  color: var(--atcb-modal-date-btn-text);
}

.atcb-date-btn-details {
  opacity: 1;
  padding: .8em;
  text-align: left;
}

.atcb-rtl .atcb-date-btn-details {
  text-align: right;
}

.atcb-date-btn-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1em;
}

.atcb-subevent-btn:hover .atcb-date-btn-details {
  opacity: 0;
}

.atcb-subevent-btn:hover .atcb-date-btn-hover {
  opacity: 1;
}

.atcb-date-btn-headline {
  font-weight: 600;
  font-size: .9em;
  margin-bottom: .5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.atcb-date-btn-content {
  font-size: .8em;
  color: var(--atcb-modal-date-btn-text-2);
}

.atcb-date-btn-content-location {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.atcb-date-btn-content-icon {
  display: inline-block;
  height: 0.8em;
  width: 1.3em;
  flex: 0 0 1.3em;
}

.atcb-rtl .atcb-date-btn-content-icon {
  margin-right: 0;
  margin-left: .5em;
}

.atcb-date-btn-content-icon svg {
  height: 100%;
  fill: currentcolor;
  width: auto;
}

.atcb-date-btn-content + .atcb-date-btn-content {
  margin-top: .3em;
}

.atcb-date-btn-content-recurr-icon {
  padding-left: .3em;
}

/******************************
 * Checkmark Overlay
 */

.atcb-checkmark {
  display: none;
}

.atcb-saved .atcb-checkmark {
  box-sizing: content-box;
  color: var(--atcb-text);
  display: block;
  position: absolute;
  top: -1.2em;
  right: -.3em;
  padding: .5em;
  background: var(--atcb-checkmark-background);
  border-radius: 100%;
  height: 2em;
}

.atcb-button.atcb-active:not(.atcb-modal-style, .atcb-dropoverlay) .atcb-checkmark,
.atcb-button.atcb-single:focus .atcb-checkmark,
.atcb-button.atcb-single:hover .atcb-checkmark {
  top: -1.07em;
  right: -.17em;
}

.atcb-checkmark svg {
  height: 100%;
  fill: currentcolor;
  width: auto;
}

/******************************
 * Background Overlay
 */

#atcb-bgoverlay {
  animation: atcb-bgoverlay-animate 0.1s ease 0s 1 normal forwards;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background-color: var(--atcb-overlay-background);
  box-sizing: border-box;
  display: flex;
  
  /* could become simply 100dvh in the future - with regular padding then */
  height: calc(100vh + 100px);
  left: 0;
  right: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  overflow-y: auto;
  padding: 20px 20px 130px;
  position: fixed;
  width: 100vw;
  z-index: 14000000;
}

#atcb-bgoverlay.atcb-animate-bg {
  animation: atcb-bgoverlay-animate 0.3s ease 0s 1 normal forwards;
}

#atcb-bgoverlay.atcb-no-animation {
  animation: none;
  opacity: 1;
}

#atcb-bgoverlay.atcb-no-bg {
  animation: none;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  opacity: 0;
}

@keyframes atcb-bgoverlay-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#atcb-bgoverlay.atcb-click:hover {
  cursor: var(--bg-cursor);
}

/******************************
 * Icon specifics
 */

.atcb-icon .atcb-icon-apple svg {
  fill: currentcolor;
}

.atcb-icon .atcb-icon-ical svg {
  fill: currentcolor;
}

.atcb-icon .atcb-icon-ms365 svg {
  fill: var(--icon-ms365-color);
}

.atcb-icon .atcb-icon-yahoo svg {
  fill: var(--icon-yahoo-color);
}

.atcb-icon .atcb-icon-google svg,
.atcb-icon .atcb-icon-msteams svg,
.atcb-icon .atcb-icon-outlookcom svg {
  filter: var(--icon-filter);
}